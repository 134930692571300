<template>
<!-- 供应商合同管理列表 合同详情 -->
  <div class="mainform">
    <div class="mainHeader">合同签约
      <span @click="back" class="back">返回></span>
    </div>
    <div class="form">
      <el-form class="content bascform" :class="{disabled:formDisabled}" :disabled="formDisabled" :model="form" label-width="130px">
        <div class="col col4" style="vertical-align: top;">
          <el-form-item label="合同编号">
            <span class="onlyText">
            {{form.number}}
            </span>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="业务类型">
            <span class="onlyText">
              {{form.businessType==1?'生产物料类':form.businessType==2?'资产类':form.businessType==3?'服务类':form.businessType==4?'基建类'
                :form.businessType==5?'委托采购':'其它协议'}}
            </span>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="合同名称">
            <span class="onlyText">
              {{form.name}}
            </span>
          </el-form-item>
        </div>
        <div  class="col col4">
          <el-form-item label="合同生效日期">
              <el-date-picker type="date" placeholder="选择日期" v-model="form.startDate" style="width: 45%;"></el-date-picker>
              <el-date-picker type="date" placeholder="选择日期" v-model="form.endDate" style="width: 55%;"></el-date-picker>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="客户主体" prop="mySubject">
              <div class="flex-table">
                <el-form-item>
                  <el-select @change="ScopeChange" v-model="form.mySubject" style="width: 30%;">
                    <el-option :key="index" v-for="(itme,index) in businessScopeList" :label="itme.companyDesc"
                      :value="itme.businessScope"></el-option>
                  </el-select>
                  <el-select @change="companyChange" v-model="form.mySubjectCode" style="width: 70%;">
                    <el-option :key="index" v-for="(itme,index) in companyCodeList" :label="itme.companyCode"
                      :value="itme.companyCode"></el-option>
                  </el-select>
              </el-form-item>
            </div>
          </el-form-item>
        </div>
        <!-- <div class="col col4">
          <el-form-item label="合同有效期">
            <span class="onlyText">
              {{form.contractEffective}}
            </span>
          </el-form-item>
        </div> -->
        <div class="col col4">
          <el-form-item label="签约负责人">
            <el-input v-model="form.jpersonInCharge"></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="关联订单">
            <el-input v-model="form.associatedOrder"></el-input>
          </el-form-item>
        </div>
        <div class="col col4" style="vertical-align: top;">
          <el-form-item label="我方主体">
            <el-input  v-model="form.subject"></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="状态">
            <span class="onlyText">
              {{form.status==0?'待审核':form.status==1?'驳回':form.status==2?'待签章':form.status==3?'待他签'
                :form.status==4?'已签章':form.status==6?'作废':form.status==7?'待确认':form.status==8?'待归档': '草稿'}}
            </span>
          </el-form-item>
        </div>
        <div class="col col4" style="vertical-align: top;">
          <el-form-item label="合同介质">
          <el-select v-model="form.medium" placeholder="">
            <el-option label="纸质" :value="0"></el-option>
            <el-option label="电子合同" :value="1"></el-option>
          </el-select>
        </el-form-item>
        </div>
        <div class="col col4" style="vertical-align: top;">
          <el-form-item label="我方是否签章">
            <span class="onlyText">
              {{form.supplierIsSignature==0?'是':'否'}}
            </span>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="合同文件" :class="{isreadonly:formDisabled}" prop="file">
            <el-upload
                :action="actionURL"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :headers="tokenHeader"
                :on-success="handleSuccess"
                multiple
                :on-exceed="handleExceed"
                :file-list="form.file"
              >
              <el-button v-if="!formDisabled" size="small" type="primary" plain>上传合同文件</el-button>
            </el-upload>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="合同文件附件" :class="{isreadonly:formDisabled}" prop="file">
            <el-upload
                :action="actionURL"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :headers="tokenHeader"
                :on-success="handleSuccess"
                multiple
                :on-exceed="handleExceed"
                :file-list="file2"
              >
              <el-button v-if="!formDisabled" size="small" type="primary" plain>上传合同文件</el-button>
            </el-upload>
          </el-form-item>
        </div>
      </el-form>
      <!-- 供应商草稿 -->
      <div class="action" v-if="form.status == 9">
        <el-button  type="primary" @click="editContract">编辑</el-button>
        <el-button  type="primary" size="medium" @click="submitBtn" >提交</el-button>
      </div>
      <!-- 待确认 草稿 -->
      <div class="action" v-if="form.status == 7">
        <el-button  type="primary" @click="editContract">编辑</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { request, exportForm } from '@/assets/js/http.js'
import { MIME } from '@/assets/js/mime.js'
import { baseHost } from '@/assets/js/PublicData.js'
export default {
  name: 'ContractSign',
  data () {
    return {
      formDisabled: true,
      form: {},
      businessScopeList: [],
      companyCodeList: [],
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      file2: [],
      actionURL: baseHost.SupplierRegHost + '/api/file/m/file/uploadToServer'
    }
  },
  created () {
    this.contractDetails()
  },
  methods: {
    contractDetails () {
      request('/api/sap/businessScope/getBusinessScopeList', 'get').then((response) => {
        this.businessScopeList = response
      })
      const id = this.$route.query.id
      request('/api/pact/contract/queryOne?id=' + id, 'post').then((res) => {
        if (res.code === '200') {
          this.form = res.data
          console.log(this.form)
          for (let i = 0; i < this.form.file.length; i++) {
            if (this.form.file[i].type !== '1') {
              this.file2.push(this.form.file[i])
              this.form.file.splice(i, 1)
              i--
            }
          }
        }
      })
    },
    // 我方签约主体
    ScopeChange: function () {
      this.form.companyCode = ''
      if (this.form.mySubject !== '') {
        request('/api/sap/getCompanyCodeName/' + this.form.mySubject, 'get').then((response) => {
          this.companyCodeList = response
        })
      }
    },
    companyChange: function (data) { // 公司
      for (var item of this.companyCodeList) {
        if (item.companyCode === data) {
          this.form.companyName = item.companyName
        }
      }
    },
    submitBtn: function () { // 提交
      const obj = {
        ...this.form,
        status: 7
      }
      request('/api/pact/contract/updateOperate', 'POST', obj).then((response) => {
        if (response.code === '200') {
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.$router.push({
            path: '/ContractOrder/SupplierContractList'
          })
        }
      })
    },
    editContract () {
      this.formDisabled = false
      const obj = {
        ...this.form
      }
      const id = this.$route.query.id
      request('/api/pact/contract/updateOperate?' + id, 'POST', obj).then((response) => {
        if (response.code === '200') {
          this.form = response.data
          this.$router.push({
            path: '/ContractOrder/SupplierDraft', query: { id: this.form.id }
          })
        }
      })
      this.disabled = false
    },
    handlePreview (file) {
      exportForm('/api/file/m/file/downloadFile/' + file.id, 'get').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        })
    },
    handleRemove (file) {
      for (var i = 0; i < this.form.file.length; i++) {
        if (this.form.file[i].id === file.id) {
          this.form.file.splice(i, 1)
        }
      }
    },
    handleExceed (files, fileList) {
      // this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(element => {
          this.form.file.push(element)
        })
      }
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
          eleLink.download = fileName
        }
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    },
    // 返回
    back: () => {
      window.history.back()
    }
  }
}
</script>
<style scoped lang="scss">
 @import '@/assets/css/form.scss';
 @import '@/assets/css/elform.scss';

</style>
